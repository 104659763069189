import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { ErrorBoundary } from 'react-error-boundary'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import 'moment/locale/ru'
import './sass/main.sass'

import store from './store'
import { theme } from './theme/theme'
import App from './app/App'

import Moment from 'react-moment'
import moment from 'moment'

moment.locale('ru')
moment.defaultFormat = 'DD.MM.YYYY'
Moment.globalLocale = 'ru'
Moment.globalFormat = 'DD.MM.YYYY'

class CustomString extends String {
  charAt = (_: number) => this.valueOf()
}

const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
const customWeekDays = weekDays.map((day) => new CustomString(day) as string)

class CustomDateAdapter extends AdapterMoment {
  getWeekdays = () => customWeekDays
}

const ErrorFallback = lazy(() => import('./pages/error-fallback'))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <ErrorBoundary
        fallbackRender={() => (
          <Suspense fallback="">
            <ErrorFallback />
          </Suspense>
        )}
      > */}
      <LocalizationProvider dateAdapter={CustomDateAdapter}>
        <App />
      </LocalizationProvider>
      {/* </ErrorBoundary> */}
    </ThemeProvider>
  </Provider>
)
