import { ApiAnswer, Prize, User } from 'types'
import moment from 'moment'
import { QrCheckProps } from 'components/upload-receipt/types'
import { withPrivateAPIToken, apiFetch } from './config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toFormData = (props: any): FormData => {
  const data = new FormData()
  Object.keys(props).forEach((key) => {
    if (typeof props?.[key] !== 'undefined') {
      data.append(key, props[key])
    }
  })
  return data
}

export const userInfo = async (): Promise<ApiAnswer<User>> =>
  withPrivateAPIToken({
    url: `/user/?n=${moment().unix()}`,
    method: 'get',
  })

export const prizeInfo = async (): Promise<ApiAnswer<Prize[]>> =>
  withPrivateAPIToken({
    url: '/user/priz',
    method: 'get',
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prizeOrder = async (props: { priz_id: number }): Promise<ApiAnswer<any>> =>
  withPrivateAPIToken({
    url: '/user/priz/order',
    method: 'post',
    data: toFormData(props),
  })

export const userRegister = async (props: { rules: boolean }): Promise<ApiAnswer<User>> =>
  withPrivateAPIToken({
    url: '/user',
    method: 'post',
    data: toFormData(props),
  })

export const receiptRegister = async (props: QrCheckProps) =>
  withPrivateAPIToken({
    url: '/user/check',
    method: 'post',
    data: toFormData(props),
  })

export const receiptUpload = async (props: { image: Blob }) =>
  withPrivateAPIToken({
    url: '/user/check/upload',
    method: 'post',
    data: toFormData(props),
  })

export const get = async (url: string, params?: any) =>
  apiFetch({
    url,
    method: 'get',
    params,
  })
