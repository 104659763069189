import { createTheme, Theme } from '@mui/material/styles'
import palette from './palette'
import font from './font'

const {
  spacing,
  breakpoints: { up, down, values },
  transitions,
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: { values: { ...values, md: 960, lg: 1220, xl: 1920 } },
  palette,
  font,

  typography: {
    fontSize: 16,
    fontFamily: font.primary,

    h2: {
      fontSize: 30,
      fontWeight: 600,
      textAlign: 'center',
      textTransform: 'uppercase',
      [down('sm')]: { fontSize: 22 },
    },

    h3: {
      fontSize: 20,
      fontWeight: 600,
      textAlign: 'center',
      textTransform: 'uppercase',
      [down('sm')]: { fontSize: 16 },
    },

    h4: {
      fontSize: 18,
      fontWeight: 700,
      textAlign: 'center',
      textTransform: 'uppercase',
      [down('sm')]: { fontSize: 16 },
    },

    body1: {
      fontSize: 20,
      fontWeight: 500,
      [down('sm')]: { fontSize: 15 },
    },
  },

  components: {
    // #region Button
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          height: 44,
          lineHeight: 1,
          fontWeight: 600,
          borderRadius: spacing(1),
          textTransform: 'uppercase',
        },

        contained: {
          boxShadow: 'none!important',
          '&:hover': {
            boxShadow: 'none',
          },
        },

        sizeSmall: { padding: 3, height: 40, minWidth: 40, borderColor: '#C3D3E6' },

        fullWidth: { textAlign: 'center' },

        containedSecondary: {
          color: palette.secondary.contrastText,
          '&:hover': {
            boxShadow: 'none',
          },
          ':disabled': {
            backgroundColor: '#8CA394',
            fontSize: 15,
            textTransform: 'unset',
            fontWeight: 500,
          },
        },

        containedPrimary: {
          backgroundColor: palette.primary.main,
          '&:hover': {
            color: '#FFED13',
          },
        },

        outlinedPrimary: {
          '&:hover': {
            backgroundColor: 'rgba(19, 42, 94,0)',
            boxShadow: 'none',
            color: '#3F60AF',
            border: '1px solid #3F60AF',
          },
        },
      },
    },
    // #endregion

    // #region Input
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 19,
          color: palette.text.secondary,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: { alignItems: 'flex-start', marginLeft: -9, marginRight: 0 },
        label: {
          fontSize: 14,
          paddingTop: 9,
          userSelect: 'none',
          lineHeight: 1.3,
          textAlign: 'left',
          color: '#5C5C5C',
          '& a': {
            color: palette.text.primary,
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline', color: palette.text.primary },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 18,
          marginTop: spacing(2),

          '& input:-webkit-autofill': {
            borderRadius: spacing(3 / 2),
            WebkitBoxShadow: `0 0 0 30px ${palette.background.paper} inset !important`,
            WebkitTextFillColor: `${palette.text.secondary} !important`,
          },

          '&:before': {
            borderBottom: `1px solid ${palette.secondary.main} !important`,
          },
        },
        colorSecondary: {
          color: palette.text.secondary,
          // '& input': { textAlign: 'center' },
          '&:before': {
            borderBottom: `1px solid ${palette.secondary.main} !important`,
          },
        },
        multiline: {
          '& textarea': {
            padding: spacing(1 / 2),
            border: `1px solid ${palette.primary.main}`,
            borderRadius: 4,
            boxSizing: 'border-box',
          },
          '&:before, &:after': { content: 'none', borderBottom: 'none !important' },
          '&.Mui-error': { '& textarea': { borderColor: palette.error.main, borderWidth: 2 } },
        },

        input: { padding: '4px 6px 5px', '&:focus': { backgroundColor: 'transparent' } },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 11,
          position: 'absolute',
          top: '104%',
          marginLeft: 6,
          marginRight: 6,
          marginTop: 0,
          lineHeight: 1.1,
          color: palette.error.main,
        },
        contained: {
          marginLeft: 3,
          marginRight: 5,
          fontWeight: 400,
          marginTop: 0,
          color: palette.error.main,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          color: 'inherit',
          transform: 'translate(6px,20px) scale(1)',
          '&.Mui-focused': { color: palette.text.secondary },
          '&.MuiInputLabel-shrink': { transform: 'translate(6px,0) scale(0.75)', color: palette.text.secondary },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          padding: 0,
          color: palette.text.secondary,
          boxSizing: 'border-box',
          borderRadius: spacing(3 / 2),
          borderBottomLeftRadius: spacing(3 / 2),
          borderBottomRightRadius: spacing(3 / 2),
          backgroundColor: palette.background.paper,

          '&.Mui-error': { borderColor: palette.error.main, color: palette.error.main },
          '&.Mui-focused': { backgroundColor: palette.background.paper },
          '&:hover': { backgroundColor: palette.background.paper },
        },
        input: {
          fontWeight: 500,
          padding: '14px 12px 14px 20px',
          '&:focus': { backgroundColor: 'transparent' },
        },
        underline: { '&:before, &:after': { content: 'none' } },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: { color: 'inherit', top: 'initial', '& svg': { display: 'block' } },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: { width: 65, height: 31, padding: 0 },
        switchBase: {
          padding: 0,
          margin: 4,
          color: '#fff',
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(34px)',
            '& .MuiSwitch-thumb:before': {
              background: 'linear-gradient(to right, #FF8D00, #FFC379)',
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: palette.primary.light,
              borderColor: palette.primary.light,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': { '&:before': { background: `none ${palette.text.disabled}` } },
          '&.Mui-disabled + .MuiSwitch-track': { borderColor: palette.text.disabled },
        },
        thumb: {
          boxSizing: 'border-box',
          width: 23,
          height: 23,
          boxShadow: 'none',
          '&:before': {
            content: "''",
            background: `none ${palette.primary.main}`,
            borderRadius: 23 / 2,
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          },
        },
        track: {
          opacity: 1,
          borderRadius: 31 / 2,
          boxSizing: 'border-box',
          border: `2px solid ${palette.primary.main}`,
          backgroundColor: '#fff',
          transition: transitions.create(['background-color'], { duration: 500 }),
        },
      },
    },
    // #endregion

    // #region Dialog
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(1px)',
            [down('sm')]: { backdropFilter: 'none' },
          },
        },
        paper: {
          width: '100%',
          borderRadius: spacing(2),
          padding: spacing(3),
          boxSizing: 'border-box',
          paddingBottom: spacing(5),
          background: palette.background.paper,
          color: '#5C5C5C',
          [down('sm')]: { borderRadius: 0, border: 'none', paddingBottom: spacing(3) },
        },
        paperWidthSm: {
          maxWidth: 450,
        },
        paperWidthMd: {
          maxWidth: 720,
        },
        paperFullScreen: { height: 'initial', maxWidth: 'initial' },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          color: palette.text.primary,
          fontSize: 22,
          fontWeight: 700,
          textTransform: 'uppercase',
          textAlign: 'center',
          paddingTop: spacing(3),
          position: 'relative',
          display: 'block',
          zIndex: 1,
          paddingBottom: spacing(1),
          // [down('sm')]: { fontSize: 32, paddingTop: spacing(3) },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#5C5C5C',
          textAlign: 'center',
          fontFamily: font.secondary,
          fontWeight: 500,
          fontSize: 16,
          // maxWidth: 385,
          margin: '0 auto',
          '& ol': { marginBottom: 0, [down('sm')]: { paddingInlineStart: 15 } },
          '& li': {
            textAlign: 'left',
            marginBottom: spacing(1),
            '& a': { color: palette.text.primary },
            [down('sm')]: { fontSize: 14 },
          },
          [down('sm')]: { paddingLeft: 0, paddingRight: 0 },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          maxWidth: 300,
          margin: '0 auto',
        },
      },
    },
    // #endregion

    // #region Table
    MuiTable: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          marginBottom: spacing(3),
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderBottom: 'none',
          // lineHeight: 2,
          fontFamily: font.secondary,
          '& a': { color: 'inherit' },
          // [down('md')]: { lineHeight: 1.1 },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '& tr': {
            '& th': {
              fontSize: 20,
              fontWeight: 600,
              fontFamily: font.primary,
              color: palette.text.primary,
              textTransform: 'uppercase',
              [down('sm')]: { fontSize: 16, fontWeight: 50 },

              '&:first-of-type': { paddingLeft: spacing(4), [down('sm')]: { paddingLeft: spacing(2) } },
              '&:last-of-type': { paddingRight: spacing(4), [down('sm')]: { paddingRight: spacing(2) } },
            },
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& tr': {
            borderRadius: spacing(1),
            '&:nth-of-type(odd)': {
              backgroundColor: '#D9E3DC',
              '& td': {
                color: palette.text.secondary,
                '&:first-of-type': { borderTopLeftRadius: spacing(1), borderBottomLeftRadius: spacing(1) },
                '&:last-of-type': { borderTopRightRadius: spacing(1), borderBottomRightRadius: spacing(1) },
              },
            },

            '& td': {
              fontSize: 20,
              fontWeight: 500,
              color: palette.text.secondary,

              '&:first-of-type': { paddingLeft: spacing(4), [down('sm')]: { paddingLeft: spacing(2) } },
              '&:last-of-type': { paddingRight: spacing(4), [down('sm')]: { paddingRight: spacing(2) } },

              '& .t_head_m': { color: palette.text.primary, fontWeight: 600, lineHeight: 1.1 },

              [down('sm')]: {
                fontWeight: 500,
                fontSize: 16,
                lineHeight: 1.1,
                paddingLeft: spacing(1),
                paddingRight: spacing(1),
              },
            },
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        toolbar: { paddingLeft: spacing(1) },
        actions: { marginLeft: spacing(2) },
      },
    },

    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'center',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 14,
          '&.Mui-selected': {
            backgroundColor: palette.secondary.main,
            color: palette.primary.contrastText,
            '&:hover,&:active': { backgroundColor: palette.secondary.light },
          },
        },

        ellipsis: { '&:hover': { color: palette.secondary.contrastText } },

        textPrimary: {
          color: palette.text.primary,
          '&:hover,&:active': { backgroundColor: palette.secondary.light },
        },
        textSecondary: { color: palette.secondary.light },
      },
    },
    // #endregion

    // #region Other
    MuiPaper: {
      styleOverrides: {
        root: { color: palette.text.secondary },
        elevation1: {
          boxShadow: 'none',
          boxSizing: 'border-box',
          borderRadius: spacing(3 / 2),
          padding: spacing(4),
          [down('sm')]: { borderRadius: 0 },
        },

        elevation8: {
          padding: 0,
          [down('sm')]: { padding: 0 },
        },
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: { borderRadius: spacing(1 / 2) },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1,
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: { top: 60 },
        paper: {
          height: 'calc(100% - 60px)',
          bottom: 0,
          top: 'initial',
          minWidth: 300,
          boxShadow: 'none',
          // borderTopLeftRadius: spacing(2),
          // borderBottomLeftRadius: spacing(2),
          backgroundColor: palette.primary.light,
          [down('sm')]: { minWidth: 'initial', width: '100%', borderRadius: 0 },
        },

        paperAnchorRight: {
          overflowY: 'initial',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: -10,
            left: -20,
            height: 20,
            width: 20,
            borderTop: `10px solid ${palette.primary.light}`,
            borderRight: `10px solid ${palette.primary.light}`,
            borderRadius: '0 20px 0 0',
          },
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          fontSize: 14,
          color: palette.text.primary,
          boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
          [down('sm')]: { fontSize: 12 },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          padding: 0,
          overflow: 'hidden',
          marginBottom: 0,
          borderRadius: spacing(3 / 2),
          '&:before': { content: 'none' },
          '&:first-of-type, &:last-of-type': { borderRadius: spacing(3 / 2) },
          '&.Mui-expanded': { color: palette.primary.light },
          [down('sm')]: { padding: 0 },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
          fontFamily: font.primary,
          boxSizing: 'border-box',
          borderBottom: `1px solid ${palette.primary.main}`,
          '&.Mui-expanded': {
            // borderBottom: `1px solid rgba(0,0,0,0.07)`,
            // backgroundColor: palette.primary.main,
          },

          '&:hover': {
            color: palette.primary.light,
            '& .MuiAccordionSummary-expandIconWrapper': { color: palette.primary.light },
            '&.Mui-expanded': {
              '& .MuiAccordionSummary-expandIconWrapper': { color: 'inherit' },
            },
          },
        },
        content: {
          margin: '20px 0',
          transition: 'all 0.15s ease-in-out',
          '&.Mui-expanded': {
            margin: '16px 0',
            minHeight: 'initial',
          },
        },
        expandIconWrapper: {
          fontSize: '2.7rem',
          color: 'inherit',
          '&.Mui-expanded': { color: 'inherit' },
          '& svg': { fontSize: 'inherit' },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          // backgroundColor: palette.primary.dark,
          padding: spacing(1),
          '& p': { lineHeight: 1.4 },
          '& a': { fontWeight: 500, '&:hover': { color: palette.text.secondary, textDecoration: 'none' } },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: palette.text.primary,
            '&:hover': { color: palette.text.primary },
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          whiteSpace: 'initial',
          color: palette.text.primary,
          '&:hover': { color: palette.primary.contrastText },

          '&.Mui-selected': {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.main,
            '&:hover': {
              backgroundColor: palette.primary.main,
              color: palette.primary.contrastText,
            },
          },
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontSize: 11,
          lineHeight: 1.2,
          marginBottom: 19,
          fontFamily: font.other,
          fontWeight: 500,
          borderRadius: spacing(1 / 2),
          backgroundColor: palette.common.white,
          boxShadow: '0 0.2em 1em rgba(0,0,0,0.15)',
          [up('sm')]: { minWidth: 'initial' },
          [down('sm')]: { fontSize: 10 },
        },
        message: { padding: 0, width: '100%' },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        paper: { padding: 0 },
        option: { '&:hover': { backgroundColor: palette.primary.main } },
      },
    },

    MuiTabs: {
      styleOverrides: {
        scroller: {
          width: 'auto',
          flex: 'initial',
          margin: '0 auto',
        },
        centered: {
          overflow: 'hidden',
          backgroundColor: palette.common.white,
          borderRadius: spacing(10),
        },

        indicator: { display: 'none' },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          padding: '1.3em 2em',
          '&.Mui-selected': { color: palette.text.primary, backgroundColor: '#003c96' },
          '&:first-of-type': { paddingLeft: spacing(8) },
          '&:last-of-type': { paddingRight: spacing(8) },

          [down('md')]: {
            padding: '0.9em 0.7em',
            '&:first-of-type': { paddingLeft: spacing(5) },
            '&:last-of-type': { paddingRight: spacing(5) },
          },
        },
      },
    },
    // #endregion
  },
})
