import axios, { AxiosRequestConfig } from 'axios'
import { ApiAnswer, ApiAnswerStatus } from 'types'
// import { sendTopMessage } from '../app/App'
import { Cookies } from 'react-cookie'

const { REACT_APP_API_URL = '/' } = process.env

const client = axios.create({
  baseURL: `${REACT_APP_API_URL.replace(/^\/+/, '')}/`,
  responseType: 'json',
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiFetch = async (config: AxiosRequestConfig): Promise<ApiAnswer<any>> => {
  try {
    const res = await client.request(config)
    return res?.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      data: error?.response?.data,
      status: error?.response?.status,
      message: error?.response?.data?.message ?? error?.response?.message,
    }
  }
}

export const withPrivateAPIToken = async (config: AxiosRequestConfig) => {
  const cookies = new Cookies()
  const access_token = cookies.get('access_token')

  if (access_token) {
    const test = await apiFetch({
      ...config,
      headers: { 'X-Access-Token': `Bearer ${access_token}` },
    }).then(async (res) => {
      console.log(res?.status, config?.url)

      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED && res?.status !== -1) return res

      console.log(res?.status, 'UNAUTHENTICATED', config?.url)
      // sendTopMessage({ src: 'activity', type: 'token', payload: 'new' })

      // alert(res?.status)

      // const new_access_token = cookies.get('access_token')
      // if (new_access_token) {
      //   return await apiFetch({
      //     ...config,
      //     headers: { 'X-Access-Token': `Bearer ${new_access_token}` },
      //   })
      // }
      return { status: res?.status ?? 401, message: { info: ['fetchError', config.url ?? ''] }, data: undefined }
    })

    return test
  }

  return apiFetch(config)
}
