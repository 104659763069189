import React from 'react'
import { Box, Grid } from '@mui/material'
import { Container } from '@mui/system'
import { ArrowBack, EmailOutlined } from '@mui/icons-material'
import useStyles from './style'
import { useReduxSelector } from '../../hooks'
import { useCookies } from 'react-cookie'

export const Header = () => {
  const classes = useStyles()
  const { user } = useReduxSelector((state) => state.auth)

  const [cookies] = useCookies(['access_token', 'main_url'])

  const back = () => {
    document.location = decodeURI(cookies.main_url)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.info}>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" style={{ margin: '0px auto' }}>
            <Grid xs={'auto'}>
              <a href="mailto:info@fc-rostov.ru">
                <EmailOutlined style={{ fontSize: 18, marginTop: 2 }} />
                &nbsp;&nbsp;
              </a>

              <a href="mailto:info@fc-rostov.ru" style={{ position: 'relative', display: 'inline-block', top: -3 }}>
                info@fc-rostov.ru
              </a>
            </Grid>
            <Grid xs={'auto'}>
              <Box pl={3} textAlign="right">
                <Grid container alignItems="center">
                  <Grid item>
                    <a href={`${decodeURI(cookies.main_url)}/profile`}>
                      <img
                        width={18}
                        style={{ marginRight: 5, marginBottom: -3 }}
                        height={18}
                        src="https://fc-rostov.ru/imgs/icon/login.svg"
                        alt="Профиль"
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a href={`${decodeURI(cookies.main_url)}/profile`}>{user?.first_name}</a>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box py={1}>
        <Grid container maxWidth="lg" justifyContent="flex-start" style={{ margin: '0px auto' }}>
          <Grid item>
            <Box px={2}>
              <img className={classes.logo} onClick={back} src="https://fc-rostov.ru/imgs/logo.svg" alt={'FC Rostov'} />
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.slogo} onClick={back}>
              Футбольный клуб «РОСТОВ»
            </Box>
            <Box className={classes.slogo2} onClick={back}>
              Официальный сайт
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.menu}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <a onClick={back}>
                <ArrowBack style={{ fontSize: 20, marginTop: 5 }} />
              </a>
            </Grid>
            <Grid item>
              <a onClick={back}>На главную</a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
