import createPalette from '@mui/material/styles/createPalette'

const palette = createPalette({
  mode: 'light',

  primary: {
    main: '#1C3C87',
    contrastText: '#fff',
  },
  secondary: {
    '100': '#D9E3DC',
    '200': '#C3D2C7',
    '300': '#C4D1C8',
    '400': '#8CA394',
    light: '#5C7E67',
    main: '#003E15',
    contrastText: '#fff',
  },

  error: { main: '#AC2C2C' },
  warning: { main: '#AB8626' },
  info: { main: '#1C3C87' },
  success: { main: '#003E15' },

  background: {
    paper: '#F8F8F8',
    default: '#fff',
  },

  text: {
    primary: '#1C3C87',
    secondary: '#5C7E67',
    disabled: '#fff',
  },

  action: {
    active: '#C4D1C8',
    hover: '#C4D1C8',
    focus: '#C4D1C8',
    selected: '#C4D1C8',
    disabled: '#D9E3DC',
    disabledBackground: '#C7D1E8',
  },

  divider: '#8CA394',
})

export default palette
