import { UserAction, USER, SET_USER } from './types'

export const initAuthState: UserAction = {
  user: {},
}

export const authReducer = (state = initAuthState, action: USER) => {
  const { type, payload } = action

  switch (type) {
    case SET_USER:
      return { ...state, ...(payload as UserAction) }

    default:
      return state
  }
}
